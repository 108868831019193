// jshint ignore: start
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import './../../tools/gen-pushid'
import uuidv4 from 'uuid/v4'
// import emailsettings from './reducers/emailsettings';

function makeid(l) {
    var text = "";
    var char_list = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < l; i++) {
        text += char_list.charAt(Math.floor(Math.random() * char_list.length));
    }
    return text;
}

export const fetchAllContacts = async () => {        
    let db = firebase.firestore();
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    var entitiesRef = db.collection("contacts");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    const querydata = querysnapshot.docs.map(doc => doc.data());
    const queryid = querysnapshot.docs.map(doc => doc.id);

    const newdocs = await querydata.map((doc, i) => {
        return {
            ...doc,
            contdocid: queryid[i]
        }
    })
 
    try {
        const response = await newdocs;
        const allcontacts = await response;
        return allcontacts;
    } catch (e) {
        console.log(e);
    }
};

export const fetchSearchContacts = async (searchvalue) => {
    let db = firebase.firestore();

    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    const newref = await querysnapshot.docs.map(d => {
        return d.id
    })
    const docRefEntity = await entitiesRef.doc(newref[0])
    const snapshot = await db.collection("contacts")
        .orderBy('name_insensitive')
        .where('name_insensitive', '>=', searchvalue.toLowerCase())
        .where('name_insensitive', '<=', searchvalue.toLowerCase() + "\uf8ff")
        .where('entity', '==', docRefEntity)
        .get()

    const querydata = snapshot.docs.map(doc => doc.data());
    const queryid = snapshot.docs.map(doc => doc.id);

    const newdocs = await querydata.map((doc, i) => {
        return {
            ...doc,
            contdocid: queryid[i]
        }
    })

    try {
        const response = await newdocs;
        const allcontacts = await response;
        return allcontacts;
    } catch (e) {
        console.log(e);
    }
};

export const fetchEmailContacts = async (refids, emailvalue) => {
    let db = firebase.firestore();

    if (emailvalue == "") {
        return "emailfalse"
    }   

    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    const newref = await querysnapshot.docs.map(d => {
        return d.id
    })
    const docRefEntity = await entitiesRef.doc(newref[0])
    const emailsnapshot = await db.collection("contacts")
        .where('email', '==', emailvalue)
        .where('entity', '==', docRefEntity)
        .get()
    const emailquerydata = await emailsnapshot.docs.map(doc => doc.data());
    const queryid = emailsnapshot.docs.map(doc => doc.id);

    try {
        if (emailquerydata.length && (queryid != refids)) {
            const reports = "emailtrue"
            return reports;
        } else {
            const reports = "emailfalse"
            return reports;
        }
    } catch (e) {
        console.log(e);
    }
};

export const fetchNewContact = async (firstname, lastname, email, phone) => {
    if (firstname.trim() === "" &&
    lastname.trim() === "" &&
    email.trim() === "" &&
    firstname.trim() === "") {
        return
    }

    let db = firebase.firestore();
    
    var newref
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const userLang = await reference.get().then(m => {
        return m.data().default_language
    })

    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    querysnapshot.docs.map(d => {
        newref = d.id
    })
    const cdate = firebase.firestore.FieldValue.serverTimestamp()

    let obj = {
        creation: cdate,
        updated: cdate,
        firstname: String(firstname),
        lastname: String(lastname),
        name: String(firstname + ' ' + lastname).trim(),
        name_insensitive: String(firstname + ' ' + lastname).trim().toLowerCase(),
        email: String(email),
        entity: db.collection("entities").doc(newref),
        pathstring: genMCPushID(),
        phone: String(phone),
        locale: userLang,
        lang: 'en',
        status: "enabled",
        subscribed: {
            default: {
                enabled: true,
                token: uuidv4(),
                password: Math.floor(100000 + Math.random() * 900000)
            }
        },
        owner: reference
    }

    const emailsnapshot = await db.collection("contacts")
        .where('email', '==', email)
        .where('entity', '==', db.collection("entities").doc(newref))
        .get()
    const emailquerydata = emailsnapshot.docs.map(doc => doc.data());

    try {
        if (emailquerydata.length && email != "") {
            const reports = "emailexist"
            return reports;
        } else {
            const response = await db.collection("contacts").add(obj);
            const reports = await response.id;
            return reports;
        }

    } catch (e) {
        console.log(e);
    }
};

export const fetchAddBulkContact = async (bulk) => {
    let db = firebase.firestore();
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const userLang = await reference.get().then(m => {
        return m.data().default_language
    })

    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    const newref = await querysnapshot.docs.map(d => {
        return d.id
    })
    const docRefEntity = await entitiesRef.doc(newref[0])

    const cdate = firebase.firestore.FieldValue.serverTimestamp()

    let newObject = await bulk.map(con => {
        return {
            ...con,
            creation: cdate,
            updated: cdate,
            name: String(con['firstname'] + ' ' + con['lastname']).trim(),
            name_insensitive: String(con['firstname'] + ' ' + con['lastname']).trim().toLowerCase(),
            entity: db.collection("entities").doc(newref[0]),
            pathstring: genMCPushID(),
            locale: userLang,
            lang: 'en',
            subscribed: {
                default: {
                    enabled: true,
                    token: uuidv4(),
                    password: Math.floor(100000 + Math.random() * 900000)
                }
            },
            owner: reference
        }
    })

    var sl = newObject;
    var out = [];
    for (var i = 0, l = sl.length; i < l; i++) {
        var unique = true;
        for (var j = 0, k = out.length; j < k; j++) {
            if ((sl[i].email === out[j].email) || (sl[i].email === "")) {
                unique = false;
            }
        }
        if (unique) {
            out.push(sl[i]);
        }
        if (sl[i].email === "") {
            out.push(sl[i])
        }
    }
    newObject = out

    var batch = db.batch();

    const propGetEmail = (myArray) => {
        const promises = myArray.map(async (myValue) => {
            const emailsnapshot = await db.collection("contacts")
                .where('email', '==', myValue['email'])
                .where('entity', '==', docRefEntity)
                .get()
            return emailsnapshot.docs.map(doc => doc.data());
        });
        return Promise.all(promises);
    }
    const ppgmresult = await propGetEmail(newObject);

    const propTestEmail = (myArray) => {
        const promises = myArray.map(async (myValue, i) => {
            if (ppgmresult[i][0]) {
                if (ppgmresult[i][0]['email'].length === 0) {
                    var nycRef = await db.collection("contacts").doc();
                    return batch.set(nycRef, myValue);
                }
            } else {
                var nycRef = await db.collection("contacts").doc();
                return batch.set(nycRef, myValue);
            }
        });
        return Promise.all(promises);
    }
    const propTestEmailResult = await propTestEmail(newObject);

    try {
        const response = await batch.commit().then(() => {
            return "success"
        });
        const importcontact = await response;
        return importcontact;
    } catch (e) {
        console.log(e);
    }
};

export const fetchDeleteContacts = async (refids) => {
    let db = firebase.firestore();

    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    const newref = await querysnapshot.docs.map(d => {
        return d.id
    })
    const docRefEntity = await entitiesRef.doc(newref[0])

    var laRef
    var batch = await db.batch();

    refids.map(m => {
        laRef = db.collection("contacts").doc(m);
        // const getValue = await laRef.get()
        // if (getValue.data().entity.id === docRefEntity.id) {
            batch.delete(laRef);
        // }
    })

    try {
        const response = await batch.commit().then(() => {
            return {deleted: true}
        });
        const allcontacts = await response;
        return allcontacts;
    } catch (e) {
        console.log(e);
    }
};

export const fetchUpdateContacts = async (refids, values) => {
    let db = firebase.firestore();

    const newObject = {
        updated: firebase.firestore.FieldValue.serverTimestamp()
    }
    if (values.firstname !== null) { newObject['firstname'] = values.firstname }
    if (values.lastname !== null) { newObject['lastname'] = values.lastname }
    if (values.name !== null) { newObject['name'] = values.firstname + ' ' + values.lastname }
    if (values.name !== null) { newObject['name_insensitive'] = (values.firstname + ' ' + values.lastname).trim().toLowerCase()}
    if (values.email !== null) { newObject['email'] = values.email }
    if (values.phone !== null) { newObject['phone'] = values.phone }
    if (values.status !== null) { newObject['status'] = values.status }
    if (values.lang !== null) { newObject['lang'] = values.lang }  
    //if (values.subscribed.default !== null) { newObject['subscribed'] = { 'default': values.subscribed.default }}

    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    const newref = await querysnapshot.docs.map(d => {
        return d.id
    })
    const docRefEntity = await entitiesRef.doc(newref[0])

    var docRef = db.collection('contacts').doc(refids);
    const getEntId = await docRef.get()

    if (getEntId.data().entity.id === newref[0]) {
        return docRef.update(newObject)
            .then(function () {
                return {
                    update: 'success'
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    } else {
        return {update: 'notallowed'}
    }

};

function base64MimeType(encoded) {
    var result = null;

    if (typeof encoded !== 'string') {
        return result;
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
        result = mime[1];
    }

    return result;
}

export const fetchSaveLogo = async (logo) => {
    let db = firebase.firestore();
    let newref
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    querysnapshot.docs.map(d => {
        newref = d.id
    })
    let newref2
    const entityreference = await db.collection("entities").doc(newref);
    const emailSettingsRef = db.collection("emailsettings");
    const querysnapshot2 = await emailSettingsRef.where("entity", "==", entityreference).get();
    querysnapshot2.docs.map(d => {
        newref2 = d.id
    })

    const myextension = base64MimeType(logo).replace('image/', '')
    if (localStorage.getItem("emailsettings")) {
        let local = JSON.parse(localStorage.getItem("emailsettings"))
        local['contentType'] = 'image/' + myextension
        local = JSON.stringify(local)
        localStorage.setItem('emailsettings', local)
    } else {
         localStorage.setItem('emailsettings', JSON.stringify({contentType: 'image/' + myextension}))
    }

    var storageRef = firebase.storage().ref();
    var mountainImagesRef = storageRef.child('user/uploads/' + firebase.auth().currentUser.uid + '/' + newref + '/logo.' + myextension);

    const uploadAndLink = async () => {
        const promises = mountainImagesRef.putString(logo, 'data_url').then(async (snapshot) => {
            if (snapshot['state'] === 'success') {
                var MYREF = firebase.storage().ref('user/uploads/' + firebase.auth().currentUser.uid + '/' + newref + '/logo.' + myextension);
                const getLynk = async () => {
                    const result = await MYREF.getDownloadURL().then(async (url) => {
                        const updateLogoTofirestore = async () => {
                            const newObject = {
                                updated: firebase.firestore.FieldValue.serverTimestamp(),
                                logourl: url
                            }

                            const docRef = await db.collection('emailsettings').doc(newref2)
                            return docRef.update(newObject).then(() => {return {newlogo: true}})
                        }
                        const s = await updateLogoTofirestore();
                        return s;
                    });
                    return result
                }
                const s_1 = await getLynk();
                return s_1;
            }
        });
        return promises
    }

    try {
        const response = await uploadAndLink().then((s) => s)
        const logo = await response;
        return logo;

    } catch (e) {
        console.log(e);
    }
};

export const fetchDeleteLogo = async () => {
    const local = JSON.parse(localStorage.getItem('emailsettings'))
    const myextension = local['contentType'].replace('image/', '')

    let db = firebase.firestore();
    let newref
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    querysnapshot.docs.map(d => {
        newref = d.id
    })
    let newref2
    const entityreference = await db.collection("entities").doc(newref);
    const emailSettingsRef = db.collection("emailsettings");
    const querysnapshot2 = await emailSettingsRef.where("entity", "==", entityreference).get();
    querysnapshot2.docs.map(d => {
        newref2 = d.id
    })
    var storageRef = firebase.storage().ref();
    var deleteImagesRef = await storageRef.child('user/uploads/' + firebase.auth().currentUser.uid + '/' + newref + '/logo.' + myextension)
    const deleteImage = deleteImagesRef.delete().then(async() => {
        return true
    }).catch(function (error) {
        return error
    });
    const deleted = await deleteImage

    var storageRef2 = firebase.storage().ref();  
    var deleteImagesRef2 = await storageRef2.child('user/thumbnails/' + firebase.auth().currentUser.uid + '/' + newref + '/logo.' + myextension)
    const deleteImage2 = deleteImagesRef2.delete().then(async () => {
        return true
    }).catch(function (error) {
        return error
    });
    const deleted2 = await deleteImage2

    if (deleted2 && deleted) {
        const newObject = {
            updated: firebase.firestore.FieldValue.serverTimestamp(),
            logourl: ''
        }
        const docRef = await db.collection('emailsettings').doc(newref2)
        return docRef.update(newObject).then(() => {
            return {removed: true}
        }).catch(function (error) {
            console.log(error);
        });
    }
};

export const fetchEmailSettings = async () => {
    let db = firebase.firestore();
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    let branding = "";
    const newref = querysnapshot.docs.map(d => {
        branding = d.data().branding
        return d.id
    })
    const entityreference = await db.collection("entities").doc(newref[0]);
    const emailSettingsRef = db.collection("emailsettings");
    const querysnapshot2 = await emailSettingsRef.where("entity", "==", entityreference).get();
    const querydata = querysnapshot2.docs.map(doc => doc.data());
    const {general, bgcolor, logourl, social, title} = querydata[0]
        try {
            const response = {
                general: general,
                bgcolor: bgcolor,
                logourl: logourl,
                social: social,
                title: title,
                branding: branding
            }
            const logo = await response;
            return logo;

        } catch (e) {
            console.log(e);
        }

};

export const fetchUpdateEmailSettings = async (color, title, facebook, instagram, twitter, medium) => {
    let db = firebase.firestore();
    let newref
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    querysnapshot.docs.map(d => {
        newref = d.id
    })
    let newref2
    const entityreference = await db.collection("entities").doc(newref);
    const emailSettingsRef = db.collection("emailsettings");
    const querysnapshot2 = await emailSettingsRef.where("entity", "==", entityreference).get();
    querysnapshot2.docs.map(d => {
        newref2 = d.id
    })

    const newObject = {
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        bgcolor: '#' + color,
        title: title,
        social: {
            facebook: facebook,
            instagram: instagram,
            twitter: twitter,
            medium: medium
        }
    }
    const docRef = await db.collection('emailsettings').doc(newref2)
    return docRef.update(newObject).then(() => {
        return {updated: true}
    }).catch(function (error) {
        console.log(error);
    });

};

export const fetchGeneralSettings = async () => {
    let db = firebase.firestore();
    let newref
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    querysnapshot.docs.map(d => {
        newref = d.id
    })
    const entityreference = await db.collection("entities").doc(newref);
    const emailSettingsRef = db.collection("generalsettings");
    const querysnapshot2 = await emailSettingsRef.where("entity", "==", entityreference).get();
    const querydata = querysnapshot2.docs.map(doc => doc.data());

    try {
        const response = querydata[0]
        const logo = await response;
        return logo;

    } catch (e) {
        console.log(e);
    }

};

export const fetchUpdateGeneralSettings = async (businessname, country, address1, address2, city, cpostal) => {
    let db = firebase.firestore();
    let newref
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    querysnapshot.docs.map(d => {
        newref = d.id
    })
    let newref2
    const entityreference = await db.collection("entities").doc(newref);
    const emailSettingsRef = db.collection("generalsettings");
    const querysnapshot2 = await emailSettingsRef.where("entity", "==", entityreference).get();
    querysnapshot2.docs.map(d => {
        newref2 = d.id
    })
    let newref3
    const emailSettingsRef2 = db.collection("emailsettings");
    const querysnapshot3 = await emailSettingsRef2.where("entity", "==", entityreference).get();
    querysnapshot3.docs.map(d => {
        newref3 = d.id
    })

    const newObject = {
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        business_name: businessname,
        country: country,
        address_line1: address1,
        address_line2: address2,
        city: city,
        cpostal: cpostal
    }

    const newObject2 = {
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        general: {
            business_name: businessname,
            country: country,
            address_line1: address1,
            address_line2: address2,
            city: city,
            cpostal: cpostal
        }
    }

    const ya = async () => {
         const docRef = await db.collection('emailsettings').doc(newref3)
         return docRef.update(newObject2).then(() => {
             return {
                 updated1: true
             }
         }).catch(function (error) {
             console.log(error);
         });
    }

    const ya2 = async () => {
        const docRef2 = await db.collection('generalsettings').doc(newref2)
        return docRef2.update(newObject).then(() => {
            return {
                updated: true
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const cenas = await ya()
    const cena2 = await ya2()

    return {
        cenas,
        cena2
    }
 
};

export const fetchValidationEmail = async (email) => {
    const query = {
        uid: firebase.auth().currentUser.uid,
        email: email
    }
    var ses = await firebase.functions().httpsCallable('validateDomain')({
        query
    }).then(result => {
        return result
    });
    try {
        return await ses;
    } catch (e) {
        console.log(e);
    }
};

export const fetchNewDomain = async (email, domain, token, dkims) => {
    let db = firebase.firestore();
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    const newref = await querysnapshot.docs.map(d => {
        return d.id
    })
    const entityreference = await db.collection("entities").doc(newref[0]);
    const emailSettingsRef = db.collection("domainsettings");
    const querysnapshot2 = await emailSettingsRef.where("entity", "==", entityreference).where("email", "==", email).get();
    let newref2 = await querysnapshot2.docs.map(d => {
        return d.id
    })

    const newObject = {
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        email_verified: true,
        token: token,
        dkims: dkims
    }

    const docRef = await db.collection('domainsettings').doc(newref2[0])
    return docRef.update(newObject).then(() => {
        return {
            updated: true
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export const fetchDomain = async () => {
    let db = firebase.firestore();
    const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    const entitiesRef = db.collection("entities");
    const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    const newref = await querysnapshot.docs.map(d => {
        return d.id
    })
    const entityreference = await db.collection("entities").doc(newref[0]);
    const emailSettingsRef = db.collection("domainsettings");
    const querysnapshot2 = await emailSettingsRef.where("entity", "==", entityreference).where("email_verified", "==", true).get();
    const querydata = querysnapshot2.docs.map(doc => doc.data());

    try {
        const response = querydata
        const domain = await response;
        return domain;

    } catch (e) {
        console.log(e);
    }

};

export const fetchUploadImage = async (id, image) => {
    const query = {
        id: id,
        uid: firebase.auth().currentUser.uid,
        image: image
    }
    var ses = await firebase.functions().httpsCallable('uploadEmailImage')({
        query
    }).then(result => {
        return result
    });
    try {
        return await ses;
    } catch (e) {
        console.log(e);
    }









    // let db = firebase.firestore();
    // const reference = await db.collection("users").doc(firebase.auth().currentUser.uid);
    // const entitiesRef = db.collection("entities");
    // const querysnapshot = await entitiesRef.where("owner", "==", reference).get();
    // const newref = querysnapshot.docs.map(d => {
    //     return d.id
    // })
    // const entityreference = await db.collection("entities").doc(newref[0]);
    // const emailSettingsRef = db.collection("emailsettings");
    // const querysnapshot2 = await emailSettingsRef.where("entity", "==", entityreference).get();
    // const newref2 = querysnapshot2.docs.map(d => {
    //     return d.id
    // })
    // const myextension = base64MimeType(image).replace('image/', '')
    // const filename = genMCPushID().substring(1);
    // const emailId = id.substring(1)
    // var storageRef = firebase.storage().ref();
    // var mountainImagesRef = storageRef.child('user/emailuploads/' + firebase.auth().currentUser.uid + '/' + newref + '/' + emailId + '/' + filename + '.' + myextension);
    // const uploadAndLink = async () => {
    //     const promises = mountainImagesRef.putString(image, 'data_url').then(async (snapshot) => {
    //         if (snapshot['state'] === 'success') {
    //             var MYREF = firebase.storage().ref('user/emailuploads/' + firebase.auth().currentUser.uid + '/' + newref + '/' + emailId + '/' + filename + '.' + myextension);
    //             const result = await MYREF.getDownloadURL().then(async (url) => url)
    //             return result
    //         }
    //     });
    //     return promises
    // }
    // try {
    //     const response = await uploadAndLink().then((s) => s)
    //     const myimage = await response;
    //     return myimage;

    // } catch (e) {
    //     console.log(e);
    // }
};