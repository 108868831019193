// jshint ignore: start
import {
    REQUEST_SAVE_LOGO,
    RECEIVE_SAVE_LOGO,
    REQUEST_DELETE_LOGO,
    RECEIVE_DELETE_LOGO,
    REQUEST_EMAIL_SETTINGS,
    RECEIVE_EMAIL_SETTINGS,
    REQUEST_UPDATE_SETTINGS,
    RECEIVE_UPDATE_SETTINGS,
} from "../types";

export const saveLogo = (logo) => ({
    type: REQUEST_SAVE_LOGO,
    logo
});

export const receiveSaveLogo = (emailsettings) => ({
    type: RECEIVE_SAVE_LOGO,
    emailsettings
})

export const deleteLogo = () => ({
    type: REQUEST_DELETE_LOGO
});

export const receiveDeleteLogo = (emailsettings) => ({
    type: RECEIVE_DELETE_LOGO,
    emailsettings
})

export const requestEmailSettings = () => ({
    type: REQUEST_EMAIL_SETTINGS,
})

export const receiveEmailSettings = (emailsettings) => ({
    type: RECEIVE_EMAIL_SETTINGS,
    emailsettings
})

export const updateEmailSettings = (color, title, facebook, instagram, twitter, medium) => ({
    type: REQUEST_UPDATE_SETTINGS,
    color,
    title,
    facebook,
    instagram,
    twitter,
    medium
})

export const receiveUpdateEmailSettings = (emailsettings) => ({
    type: RECEIVE_UPDATE_SETTINGS,
    emailsettings
})



