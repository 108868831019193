// jshint ignore: start

// CONTACTS
export const ALL_CONTACT = "ALL_CONTACT";
export const NEW_CONTACT = "NEW_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const RECEIVE_CONTACT = "RECEIVE_CONTACT";
export const RECEIVE_ALL_CONTACT = "RECEIVE_ALL_CONTACT";
export const CLEAR_CONTACT = "CLEAR_CONTACT";
export const SEARCH_CONTACT = "SEARCH_CONTACT";
export const EMAIL_CONTACT = "EMAIL_CONTACT";
export const RECEIVE_EMAIL_CONTACT = "RECEIVE_EMAIL_CONTACT";
export const ADD_BULK_CONTACT = "ADD_BULK_CONTACT";
export const CLEAR_BULK_CONTACT = "CLEAR_BULK_CONTACT";
export const RECEIVE_BULK_CONTACT = "RECEIVE_BULK_CONTACT";

// EMAILSETTINGS
export const REQUEST_SAVE_LOGO = "REQUEST_SAVE_LOGO";
export const RECEIVE_SAVE_LOGO = "RECEIVE_SAVE_LOGO";
export const REQUEST_DELETE_LOGO = "REQUEST_DELETE_LOGO";
export const RECEIVE_DELETE_LOGO = "RECEIVE_DELETE_LOGO";
export const REQUEST_EMAIL_SETTINGS = "REQUEST_EMAIL_SETTINGS";
export const RECEIVE_EMAIL_SETTINGS = "RECEIVE_EMAIL_SETTINGS";
export const REQUEST_UPDATE_SETTINGS = "REQUEST_UPDATE_SETTINGS";
export const RECEIVE_UPDATE_SETTINGS = "RECEIVE_UPDATE_SETTINGS";

// GENERALSETTINGS
export const REQUEST_GENERAL_SETTINGS = "REQUEST_GENERAL_SETTINGS";
export const RECEIVE_GENERAL_SETTINGS = "RECEIVE_GENERAL_SETTINGS";
export const REQUEST_UPDATE_GENERAL_SETTINGS = "REQUEST_UPDATE_GENERAL_SETTINGS";
export const RECEIVE_UPDATE_GENERAL_SETTINGS = "RECEIVE_UPDATE_GENERAL_SETTINGS";

// DOMAINSETTINGS
export const REQUEST_EMAILVALIDATION = "REQUEST_EMAILVALIDATION";
export const RECEIVE_EMAILVALIDATION = "RECEIVE_EMAILVALIDATION";
export const REQUEST_NEWDOMAIN = "REQUEST_NEWDOMAIN";
export const RECEIVE_NEWDOMAIN = "RECEIVE_NEWDOMAIN";
export const REQUEST_DOMAIN = "REQUEST_DOMAIN";
export const RECEIVE_DOMAIN = "RECEIVE_DOMAIN";

// NOTIFICATION
export const SET_NOTIFICATION = "SET_NOTIFICATION";

// EMAIL
export const STORE_EMAIL = "STORE_EMAIL";
export const REQUEST_UPLOAD_IMAGE = "REQUEST_UPLOAD_IMAGE";
export const RECEIVE_UPLOAD_IMAGE = "RECEIVE_UPLOAD_IMAGE";