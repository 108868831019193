// jshint ignore: start
import {
    RECEIVE_EMAILVALIDATION,
    RECEIVE_NEWDOMAIN,
    RECEIVE_DOMAIN
} from "../types";

export default (state = {}, { type, domainsettings={} }) => {
    switch (type) {
    case RECEIVE_EMAILVALIDATION:
       return {
           ...state,
            valemail: domainsettings,
       }
    case RECEIVE_NEWDOMAIN:
        return {
            ...state,
            newdomain: domainsettings,
        }
    case RECEIVE_DOMAIN:
        return {
            ...state,
            mydomain: domainsettings,
        }
    default:
        return state;
    }
};