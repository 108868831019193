// jshint ignore: start
import {
    RECEIVE_ALL_CONTACT,
    RECEIVE_CONTACT,
    CLEAR_CONTACT,
    RECEIVE_EMAIL_CONTACT,
    RECEIVE_BULK_CONTACT,
    CLEAR_BULK_CONTACT
} from "../types";

export default (state = {}, { type, contacts={} }) => {
    switch (type) {
    case RECEIVE_ALL_CONTACT:
        return {
            ...state,
            all: contacts,
        }
    case RECEIVE_CONTACT:
        return {
            ...state,
            contact: contacts,
        }
    case CLEAR_CONTACT:
        return {
            ...state,
            emailcontact: {}
        }
    case RECEIVE_BULK_CONTACT:
        return {
            ...state,
            bulkcontact: contacts,
        }
    case CLEAR_BULK_CONTACT:
        return {
            ...state,
            bulkcontact: {}
        }
    case RECEIVE_EMAIL_CONTACT:
        return {
            ...state,
            emailcontact: contacts,
        }
    default:
        return state;
    }
};