// jshint ignore: start
import {
    RECEIVE_SAVE_LOGO,
    RECEIVE_DELETE_LOGO,
    RECEIVE_EMAIL_SETTINGS,
    RECEIVE_UPDATE_SETTINGS
} from "../types";

export default (state = {}, { type, emailsettings={} }) => {
    switch (type) {
    case RECEIVE_SAVE_LOGO:
        return {
           ...state,
           logo: emailsettings,
        }
    case RECEIVE_DELETE_LOGO:
        return {
            ...state,
            deleted: emailsettings
        }
    case RECEIVE_EMAIL_SETTINGS:
       return {
           ...state,
            settings: emailsettings,
       }
    case RECEIVE_UPDATE_SETTINGS:
        return {
            ...state,
            updated: emailsettings,
        }
    default:
        return state;
    }
};