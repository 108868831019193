// jshint ignore: start
import {
    RECEIVE_UPLOAD_IMAGE,
    STORE_EMAIL
} from "../types";

export default (state = {}, { type, email={} }) => {
    switch (type) {
    case RECEIVE_UPLOAD_IMAGE:
        return {
           ...state,
           image: email,
        }
    case STORE_EMAIL:
        return {
           ...state,
           myemail: email
        };
    default:
        return state;
    }
};