// jshint ignore: start
import { combineReducers } from "redux";
import contacts from "./reducers/contacts";
import emailsettings from "./reducers/emailsettings";
import generalsettings from "./reducers/generalsettings";
import domainsettings from "./reducers/domainsettings";
import notification from "./reducers/notification";
import email from "./reducers/email";

export default combineReducers({
    contacts,
    emailsettings,
    generalsettings,
    notification,
    domainsettings,
    email
});