// jshint ignore: start
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    ALL_CONTACT,
    NEW_CONTACT,
    DELETE_CONTACT,
    UPDATE_CONTACT,
    SEARCH_CONTACT,
    EMAIL_CONTACT,
    ADD_BULK_CONTACT,
    REQUEST_SAVE_LOGO,
    REQUEST_DELETE_LOGO,
    REQUEST_EMAIL_SETTINGS,
    REQUEST_UPDATE_SETTINGS,
    REQUEST_GENERAL_SETTINGS,
    REQUEST_UPDATE_GENERAL_SETTINGS,
    REQUEST_EMAILVALIDATION,
    REQUEST_NEWDOMAIN,
    REQUEST_DOMAIN,
    REQUEST_UPLOAD_IMAGE
} from './types';
import {
    fetchAllContacts,
    fetchNewContact,
    fetchDeleteContacts,
    fetchUpdateContacts,
    fetchSearchContacts,
    fetchEmailContacts,
    fetchAddBulkContact,
    fetchSaveLogo,
    fetchDeleteLogo,
    fetchEmailSettings,
    fetchUpdateEmailSettings,
    fetchGeneralSettings,
    fetchUpdateGeneralSettings,
    fetchValidationEmail,
    fetchNewDomain,
    fetchDomain,
    fetchUploadImage
} from "./api";
import {
    receiveAllContact,
    receiveContact,
    receiveEmailContact,
    receiveBulkContact,
} from "./actions/contacts";
import {
    receiveSaveLogo,
    receiveEmailSettings,
    receiveUpdateEmailSettings,
    receiveDeleteLogo
} from "./actions/emailsettings"
import {
    receiveGeneralSettings,
    receiveUpdateGeneralSettings
} from "./actions/generalsettings"
import {
    receiveEmailValidation,
    receiveNewDomain,
    receiveDomain
} from "./actions/domainsettings"
import {
    receiveUploadImage
} from "./actions/email"
function* readAllContact() {
    try {
        const contacts = yield call(fetchAllContacts)

        yield put(receiveAllContact(contacts));
    } catch (e) {
        console.log(e);
    }
}

function* addContact(action) {
    try {
        const contact = yield call(fetchNewContact,
            action.firstname,
            action.lastname,
            action.email,
            action.phone,
        )
        yield put(receiveContact(contact));
    } catch (e) {
        console.log(e);
    }
}

function* addBulkContact(action) {
    try {
        const contact = yield call(fetchAddBulkContact,
            action.bulk,
        )
        yield put(receiveBulkContact(contact));
    } catch (e) {
        console.log(e);
    }
}

function* deleteContacts(action) {
    try {
        const contact = yield call(fetchDeleteContacts,
            action.refids
        )
        yield put(receiveContact(contact));
    } catch (e) {
        console.log(e);
    }
}

function* updateContacts(action) {
    try {
        const contact = yield call(fetchUpdateContacts,
            action.refids,
            action.values
        )
        yield put(receiveContact(contact));
    } catch (e) {
        console.log(e);
    }
}

function* searchContacts(action) {
    try {
        const contact = yield call(fetchSearchContacts,
            action.searchvalue,
        )
        yield put(receiveAllContact(contact));
    } catch (e) {
        console.log(e);
    }
}

function* emailContacts(action) {
    try {
        const contact = yield call(fetchEmailContacts,
            action.refids,
            action.emailvalue,
        )
        yield put(receiveEmailContact(contact));
    } catch (e) {
        console.log(e);
    }
}

function* saveLogo(action) {
    try {
        const emailsettings = yield call(fetchSaveLogo,
            action.logo,
        )
        yield put(receiveSaveLogo(emailsettings));
    } catch (e) {
        console.log(e);
    }
}

function* deleteLogo(action) {
    try {
        const emailsettings = yield call(fetchDeleteLogo,
            action.logo,
        )
        yield put(receiveDeleteLogo(emailsettings));
    } catch (e) {
        console.log(e);
    }
}

function* emailSettings() {
    try {
        const emailsettings = yield call(fetchEmailSettings)
        yield put(receiveEmailSettings(emailsettings));
    } catch (e) {
        console.log(e);
    }
}

function* updateEmailSettings(action) {
    try {
        const emailsettings = yield call(fetchUpdateEmailSettings,
            action.color,
            action.title,
            action.facebook,
            action.instagram,
            action.twitter,
            action.medium
        )
        yield put(receiveUpdateEmailSettings(emailsettings));
    } catch (e) {
        console.log(e);
    }
}

function* generalSettings() {
    try {
        const generalsettings = yield call(fetchGeneralSettings)
        yield put(receiveGeneralSettings(generalsettings));
    } catch (e) {
        console.log(e);
    }
}

function* updateGeneralSettings(action) {
    try {
        const generalsettings = yield call(fetchUpdateGeneralSettings,
            action.businessname,
            action.country,
            action.address1,
            action.address2,
            action.city,
            action.cpostal,
        )
        yield put(receiveUpdateGeneralSettings(generalsettings));
    } catch (e) {
        console.log(e);
    }
}

function* validateEmail(action) {
    try {
        const domainsettings = yield call(fetchValidationEmail,
            action.email
        )
        yield put(receiveEmailValidation(domainsettings));
    } catch (e) {
        console.log(e);
    }
}

function* newDomain(action) {
    try {
        const domainsettings = yield call(fetchNewDomain,
            action.email,
            action.domain,
            action.token,
            action.dkims
        )
        yield put(receiveNewDomain(domainsettings));
    } catch (e) {
        console.log(e);
    }
}

function* getDomain() {
    try {
        const domainsettings = yield call(fetchDomain)
        yield put(receiveDomain(domainsettings));
    } catch (e) {
        console.log(e);
    }
}

function* uploadImage(action) {
    try {
        const email = yield call(fetchUploadImage,
            action.id,
            action.image,
        )
        yield put(receiveUploadImage(email));
    } catch (e) {
        console.log(e);
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(ALL_CONTACT, readAllContact),
        takeLatest(NEW_CONTACT, addContact),
        takeLatest(DELETE_CONTACT, deleteContacts),
        takeLatest(UPDATE_CONTACT, updateContacts),
        takeLatest(SEARCH_CONTACT, searchContacts),
        takeLatest(EMAIL_CONTACT, emailContacts),
        takeLatest(ADD_BULK_CONTACT, addBulkContact),
        takeLatest(REQUEST_SAVE_LOGO, saveLogo),
        takeLatest(REQUEST_DELETE_LOGO, deleteLogo),
        takeLatest(REQUEST_EMAIL_SETTINGS, emailSettings),
        takeLatest(REQUEST_UPDATE_SETTINGS, updateEmailSettings),
        takeLatest(REQUEST_GENERAL_SETTINGS, generalSettings),
        takeLatest(REQUEST_UPDATE_GENERAL_SETTINGS, updateGeneralSettings),
        takeLatest(REQUEST_EMAILVALIDATION, validateEmail),
        takeLatest(REQUEST_NEWDOMAIN, newDomain),
        takeLatest(REQUEST_DOMAIN, getDomain),
        takeLatest(REQUEST_UPLOAD_IMAGE, uploadImage),
    ])
}