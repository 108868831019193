// jshint ignore: start
import { SET_NOTIFICATION } from '../types';

const initialstate = {
    message: "",
    open: false,
    variant: "success"
}

export default function notification(state = initialstate, action) {
    switch (action.type) {
    case SET_NOTIFICATION:
        return {
            message: action.message,
            open: action.open,
            variant: action.variant
        };
    default:
        return state;
    }
}