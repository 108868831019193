// jshint ignore: start
import 'regenerator-runtime/runtime';
import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { createPromise } from 'redux-promise-middleware';
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const initialState = {};

const sagaMiddleware = createSagaMiddleware();
const middleware = [createPromise({ types: { fulfilled: 'success' } }), thunk, sagaMiddleware];
const windowGlobal = typeof window !== 'undefined' && window

const devtools =
    process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension ?
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__() :
    f => f;

export const store = createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware),
        devtools
    )
);

sagaMiddleware.run(rootSaga);