// jshint ignore: start
import {
    RECEIVE_GENERAL_SETTINGS,
    RECEIVE_UPDATE_GENERAL_SETTINGS
} from "../types";

export default (state = {}, { type, generalsettings={} }) => {
    switch (type) {
    case RECEIVE_GENERAL_SETTINGS:
       return {
           ...state,
            settings: generalsettings,
       }
    case RECEIVE_UPDATE_GENERAL_SETTINGS:
        return {
            ...state,
            updated: generalsettings,
        }
    default:
        return state;
    }
};