// jshint ignore: start
import {
    REQUEST_EMAILVALIDATION,
    RECEIVE_EMAILVALIDATION,
    REQUEST_NEWDOMAIN,
    RECEIVE_NEWDOMAIN,
    REQUEST_DOMAIN,
    RECEIVE_DOMAIN
} from "../types";

export const requestEmailValidation = (email) => ({
    type: REQUEST_EMAILVALIDATION,
    email
})

export const receiveEmailValidation = (domainsettings) => ({
    type: RECEIVE_EMAILVALIDATION,
    domainsettings
})

export const requestNewDomain = (email, domain, token, dkims) => ({
    type: REQUEST_NEWDOMAIN,
    email,
    domain,
    token,
    dkims
})

export const receiveNewDomain = (domainsettings) => ({
    type: RECEIVE_NEWDOMAIN,
    domainsettings
})

export const requestDomain = () => ({
    type: REQUEST_DOMAIN,
})

export const receiveDomain = (domainsettings) => ({
    type: RECEIVE_DOMAIN,
    domainsettings
})

