

// jshint ignore: start
import {
    REQUEST_GENERAL_SETTINGS,
    RECEIVE_GENERAL_SETTINGS,
    REQUEST_UPDATE_GENERAL_SETTINGS,
    RECEIVE_UPDATE_GENERAL_SETTINGS
} from "../types";

export const requestGeneralSettings = () => ({
    type: REQUEST_GENERAL_SETTINGS,
})

export const receiveGeneralSettings = (generalsettings) => ({
    type: RECEIVE_GENERAL_SETTINGS,
    generalsettings
})

export const requestUpdateGeneralSettings = (businessname, country, address1, address2, city, cpostal) => ({
    type: REQUEST_UPDATE_GENERAL_SETTINGS,
    businessname,
    country,
    address1,
    address2,
    city,
    cpostal
})

export const receiveUpdateGeneralSettings = (generalsettings) => ({
    type: RECEIVE_UPDATE_GENERAL_SETTINGS,
    generalsettings
})