// jshint ignore: start
import {
    REQUEST_UPLOAD_IMAGE,
    RECEIVE_UPLOAD_IMAGE,
    STORE_EMAIL
} from "../types";

export const storeEmail = (id, content) => ({
    type: STORE_EMAIL,
    id,
    content
});

export const requestUploadImage = (id, image) => ({
    type: REQUEST_UPLOAD_IMAGE,
    id,
    image
});

export const receiveUploadImage = (email) => ({
    type: RECEIVE_UPLOAD_IMAGE,
    email
})