// jshint ignore: start
import {
  ALL_CONTACT,
  NEW_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  RECEIVE_CONTACT,
  RECEIVE_ALL_CONTACT,
  CLEAR_CONTACT,
  SEARCH_CONTACT,
  EMAIL_CONTACT,
  RECEIVE_EMAIL_CONTACT,
  ADD_BULK_CONTACT,
  CLEAR_BULK_CONTACT,
  RECEIVE_BULK_CONTACT
} from "../types";

export const allContacts = () => ({
  type: ALL_CONTACT
});

export const searchContacts = (searchvalue) => ({
  type: SEARCH_CONTACT,
  searchvalue
});

export const emailContacts = (refids, emailvalue) => ({
  type: EMAIL_CONTACT,
  refids,
  emailvalue
});

export const newContact = (firstname, lastname, email, phone) => ({
  type: NEW_CONTACT,
  firstname,
  lastname,
  email,
  phone
});

export const addBulkContact = (bulk) => ({
  type: ADD_BULK_CONTACT,
  bulk
});

export const updateContacts = (refids, values) => ({
  type: UPDATE_CONTACT,
  refids,
  values
});

export const deleteContacts = (refids) => ({
  type: DELETE_CONTACT,
  refids
});

export const receiveContact = contacts => ({
    type: RECEIVE_CONTACT,
    contacts
});

export const receiveAllContact = contacts => ({
    type: RECEIVE_ALL_CONTACT,
    contacts
});

export const receiveEmailContact = (contacts) => ({
  type: RECEIVE_EMAIL_CONTACT,
  contacts
})

export const clearContact = () => ({
  type: CLEAR_CONTACT
})

export const receiveBulkContact = (contacts) => ({
  type: RECEIVE_BULK_CONTACT,
  contacts
})

export const clearBulkContact = () => ({
  type: CLEAR_BULK_CONTACT
})