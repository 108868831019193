// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-aws-sns-handle-bounces-js": () => import("./../src/pages/aws/sns/handle-bounces.js" /* webpackChunkName: "component---src-pages-aws-sns-handle-bounces-js" */),
  "component---src-pages-completepayment-js": () => import("./../src/pages/completepayment.js" /* webpackChunkName: "component---src-pages-completepayment-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-verifysuccess-js": () => import("./../src/pages/verifysuccess.js" /* webpackChunkName: "component---src-pages-verifysuccess-js" */)
}

